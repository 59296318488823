<template>
  <div>
    <el-navbar :isOpen="sideMenu.opened" @updateIsOpen="toggle">
      <!-- top part -->
      <!--   Logo   -->
      <router-link :to="{ name: logo.link,  }" class="el-navbar__item el-navbar__item__logo" exact>
        <div :class="logo.icon"></div>
      </router-link>
      <el-navbar-spacer></el-navbar-spacer>
      <div @click="goOUT()" class="el-navbar__item" exact>
        <div :class="logout.icon"></div>
      </div>
    </el-navbar>
    <!-- viewers -->
    <div class="el-body">
      <div class="el-panel">
        <el-toolbar>
          <el-toolbar-section>
            <button
              class="el-icon-button el-icon-button--big icon-previous_page"
              @click="goBack()">
            </button>
            <h3 class="el-title">{{ item.name}}</h3>
          </el-toolbar-section>
          <el-toolbar-spacer/>
          <el-rectangle-button-container :grey="true">
            <el-rectangle-button
              @click.native="downloadFile(item)"
            >{{ $t('documents.toolbar.button.download') }}
            </el-rectangle-button>
          </el-rectangle-button-container>
        </el-toolbar>
        <div class="el-panel--vertical-padding">
          <div class="ep-preview" ref="iframeContainer" v-if="  item.extension === 'pdf'"></div>
          <div v-else>
            <img class="ep-preview" :src="previewUrl"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from 'vue';

import { mapState, mapActions } from 'vuex';
import downloadjs from 'downloadjs';

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState('application', {
      sideMenu: state => state.sideMenu,
    }),
    extension() {
      return this.item.extension;
    },
  },
  data() {
    return {
      selected: {},

      docs: [],
      show: false,
      logo: {
        link: 'guest.index',
        icon: 'icon-edocperso',
      },
      logout: {
        link: 'login.index',
        icon: 'icon-loggout',
      },

      docName: '',
      previewUrl: undefined,
      editing: false,
      params: {
        noReset: true,
        doReload: false,
      },
    };
  },
  watch: {
    previewUrl() {
      if (this.previewUrl.length && this.item.extension === 'pdf') {
        const iframe = document.createElement('iframe');
        iframe.id = 'pdfViewer';
        iframe.classList.add('ed-modal__object');
        iframe.type = 'application/pdf';
        iframe.onload = () => {
          const loadScript = document.createElement('script');
          iframe.appendChild(loadScript);
        };
        this.$refs.iframeContainer.appendChild(iframe);
        iframe.src = this.previewUrl;
      }
    },
  },
  methods: {
    ...mapActions({
      downloadMyFile: 'documents/downloadMyFile',
    }),
    toggle() {
      this.$store.dispatch('application/toggleSideMenu');
    },
    goBack() {
      this.$router.push({ name: 'guest.index' });
    },
    downloadFile(file) {
      this.downloadMyFile(file.id)
        .then((response) => {
          let filename = file.name;
          if (!filename.endsWith(`.${file.extension}`)) {
            filename = `${filename}.${file.extension}`;
          }
          downloadjs(response, filename);
        });
    },
    goOUT() {
      this.$store.dispatch('auth/logoutGuest', this.user);
    },
  },
  mounted() {
    // if (this.doc.isNew) {
    //   this.params.doReload = true;
    // }
    // if (!this.folder || !this.doc) {
    //   this.$router.push({ name: 'folders.index' });
    // } else {
    //   this.docName = this.doc.name;
    this.previewUrl = encodeURI(
      // eslint-disable-next-line max-len
      // `${process.env.VUE_APP_API_LOCATION}/index.php?api=UserDocument&a=getContentAsGet&sessionId=${Vue.$store.state.auth.session.token}&documentId=${this.item.id}`,
      `${process.env.VUE_APP_API_LOCATION}/edpDoc/getDocContent?sessionId=${Vue.$store.state.auth.session.token}&documentId=${this.item.id}`,
    );
    //     }
  },
};
</script>
